.dashboard-menu-active {
  background: rgba(218, 229, 245, 1);
  padding: 8px;
  border-radius: 5px;
  color: rgba(29, 91, 191, 1);
}
/* Small screens (mobile devices) */
@media (max-width: 576px) {
  .dashboard-menu-active {
    padding: 4px; /* Adjust the padding for small screens */
  }
}

/* Medium screens (tablets) */
@media (min-width: 577px) and (max-width: 992px) {
  .dashboard-menu-active {
    padding: 6px; /* Adjust the padding for medium screens */
  }
}

.card-dash {
  width: 5px;
  border: 1px solid rgb(0, 177, 192);
  height: 20px;
  background: rgb(0, 177, 192);
}


.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  opacity: 1;
  transform: translateY(-50%);
}

.menu-content:hover,
.menu-content.active {
  background: rgba(246, 250, 255, 1);
  color: rgba(29, 91, 191, 1);
}


.program-status .activity-name {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
}

.program-status .activity-msg{
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
}